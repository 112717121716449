import type { NextPage } from 'next';

import costGuidesSlugServerSideProps from '@oneflare-server-side-props/_costGuidesSlug';
import { PageViewType, useTrackPageView } from 'lib/analytics/trackPageViews';
import CostGuide from 'pages/oneflare.com.au/CostGuide';
import type { CostGuideSlugPageProps } from 'types/oneflare.com.au/costGuideSlug';

const Page: NextPage = (props: CostGuideSlugPageProps) => {
  useTrackPageView(PageViewType.CostGuides, null, {
    costGuidesSubPage: props.params.slug
  });

  return <CostGuide {...props} />;
};

export { Page as default, costGuidesSlugServerSideProps as getServerSideProps };
