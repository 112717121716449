/* eslint-disable react-hooks/exhaustive-deps */
import { Experiment, Variant } from '@marvelapp/react-ab-test';
import { Fragment, useCallback, useEffect, useState } from 'react';

import { AnonymousUserIdClientAdapter } from 'lib/analytics/anonymousUserId/anonymousUserIdClientAdapter';
import PageHeader, {
  HeaderKind
} from 'shared/components/PageHeader/PageHeader';
import {
  experimentJira,
  experimentVariant,
  experimentWeights
} from 'shared/utils/constants';
import { setupExperiment } from 'shared/utils/experiment';
import type { CategoryModel } from 'types/shared';

export enum OneflareHeaderExperimentKind {
  CONTROL = 'control',
  EXPERIMENT = 'experiment',
}

type OneflareHeaderProps = {
  experimentKind: OneflareHeaderExperimentKind;
  kind?: HeaderKind;
  category: Pick<CategoryModel, 'slug' | 'singular' | 'plural' | 'id' | 'noun' | 'verb'>;
  categoryId?: string | number;
  directLeadsNumberFormatted?: string;
  ctaKind?: string;
  hideStickyHeader?: boolean;
  isBot?: boolean;
  hideCta?: boolean;
  jobFormInitiatedPageName?: string;
};

const OneflareHeader = ({
  kind = HeaderKind.DEFAULT,
  experimentKind,
  category,
  categoryId = category?.id,
  ctaKind,
  directLeadsNumberFormatted = '',
  hideStickyHeader = false,
  isBot = false,
  hideCta= false,
  jobFormInitiatedPageName
}: OneflareHeaderProps) => {
  const [anonymousUserId, setAnonymousUserId] = useState<string | undefined>(undefined);

  useEffect(() => {
    const anonymousId = new AnonymousUserIdClientAdapter().getId();
    setAnonymousUserId(anonymousId);
    setupExperiment({
      jiraTicket: experimentJira,
      variantNames: experimentVariant,
      variantWeights: experimentWeights,
      anonymousUserId: anonymousId
    });
  }, []);

  const ExperimentHeader = useCallback(() => {
    if (!anonymousUserId) return null;
    return (
      <Experiment name={experimentJira} userIdentifier={anonymousUserId}>
        <Variant name={experimentVariant[0]}>
          <PageHeader
            kind={kind}
            category={category}
            ctaKind={ctaKind}
            categoryId={categoryId ?? category?.id}
            directLeadsNumber={directLeadsNumberFormatted}
            hideStickyHeader={hideStickyHeader}
            hideGuestCta={hideCta}
            jobFormInitiatedPageName={jobFormInitiatedPageName}
          />
        </Variant>
        <Variant name={experimentVariant[1]}>
          <PageHeader
            kind={kind}
            category={category}
            ctaKind={ctaKind}
            categoryId={categoryId ?? category?.id}
            directLeadsNumber={directLeadsNumberFormatted}
            isBot={isBot}
            hideStickyHeader={hideStickyHeader}
            hideGuestCta={hideCta}
            jobFormInitiatedPageName={jobFormInitiatedPageName}
          />
        </Variant>
      </Experiment>
    );
  }, [
    category,
    categoryId,
    ctaKind,
    directLeadsNumberFormatted,
    hideStickyHeader,
    isBot,
    kind,
    anonymousUserId
  ]);

  const ControlHeader = useCallback(() => {
    return (
      <PageHeader
        kind={kind}
        category={category}
        ctaKind={ctaKind}
        categoryId={categoryId ?? category?.id}
        directLeadsNumber={directLeadsNumberFormatted}
        hideStickyHeader={hideStickyHeader}
        hideGuestCta={hideCta}
        jobFormInitiatedPageName={jobFormInitiatedPageName}
      />
    );
  }, [
    category,
    categoryId,
    ctaKind,
    directLeadsNumberFormatted,
    hideStickyHeader,
    kind
  ]);

  return (
    <Fragment>
      {experimentKind === OneflareHeaderExperimentKind.CONTROL
        ? <ControlHeader />
          : <ExperimentHeader />}
    </Fragment>
  );
};

export default OneflareHeader;
